import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/homelayout";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO
        title="Terms of Service"
        image="https://www.leverid.com/thumbnail-new.png"
      />
      <Container className="contact-above">
        <Row>
          <Col>
            <h1>Terms of Service</h1>
          </Col>
        </Row>
      </Container>
      <Container className="contactpage fullwidth">
        <Container>
          <Row className="common-text">
            <h3>1. GENERAL</h3>
            <p>
              1.1. These terms of use (hereinafter the "Terms") govern the
              access and use of the LeverID application.
            </p>
            <p>
              1.2. The Terms form an inseparable part of the agreement which is
              deemed to be concluded between you and us once you have accepted
              them. By accepting these Terms, you acknowledge that you have
              read, understood, and agree to be bound by the Terms.
            </p>
            <h3>2. DEFINITIONS</h3>
            <p>
              2.1. For the purposes of these Terms, the following terms shall
              have the following meanings:
              <br />
              (a) &ldquo;Agreement&rdquo;&nbsp;&ndash; an agreement for the use
              of the Application concluded between you and us in accordance with
              the Terms;
              <br />
              (b) &ldquo;Application&rdquo;&nbsp;&ndash; the LeverID mobile
              application available on iOS and Android platforms;
              <br />
              (c) &ldquo;Intellectual Property&rdquo;&nbsp;&ndash; all existing
              and future author's rights (copyrights), including any
              neighbouring and related rights, and industrial property rights in
              and related to the Application, including all applications for
              renewal or extension of those rights, and other similar or
              equivalent rights and means of protection;
              <br />
              (d)
              &ldquo;Party&rdquo;&nbsp;or&nbsp;&ldquo;Parties&rdquo;&nbsp;&ndash;
              a common term for you and us;
              <br />
              (e) &ldquo;We&rdquo;&nbsp;or&nbsp;&ldquo;us&rdquo;&nbsp;&ndash;
              LeverID O&Uuml;, a limited liability company established and
              existing under the laws of Republic of Estonia, registry code
              16410674, address Harju maakond, Tallinn, Kristiine linnaosa, A.
              H. Tammsaare tee 47, 11316.
            </p>
            <h3>3. THE APPLICATION</h3>
            <p>
              3.1. We offer you the Application free of charge for remote
              authentication and digital signatures. We reserve the right to
              change the functionality, design, and content of the Application
              when and where necessary. We may also terminate offering the
              Application for any reason we choose.
            </p>
            <h3>4. INTELLECTUAL PROPERTY RIGHTS</h3>
            <p>
              4.1. The Application, any parts, and elements thereof (including,
              but not limited to databases, content and documentation) are and
              may be protected under the Intellectual Property rights that
              belong to us or third parties.
            </p>
            <p>
              4.2. During the validity of the Agreement, we allow you to use the
              functionality of the Application in compliance with the Agreement
              and for the purposes stated herein. We shall not give you any
              other licenses or rights and you shall not obtain any Intellectual
              Property rights to the Application or any content made accessible
              through the Application.
            </p>
            <p>
              4.3. You may not change, copy, duplicate, distribute, process,
              translate, transmit, add to other databases, or make available to
              the public the Application, or use the Intellectual Property
              concerning the Application in any other way than provided herein,
              without our prior written consent. Furthermore, you have no right
              to issue sublicenses for the use of the Application or create new
              intellectual property objects based on it.
            </p>
            <h3>5. LIABILITY</h3>
            <p>
              5.1. We shall be only liable for direct and proprietary damages
              caused to you either intentionally or due to gross negligence. We
              shall not under any circumstances be liable for any loss of profit
              or other indirect damages incurred by you under the
              Agreement.&nbsp;
            </p>
            <p>
              5.2. The Application is provided on &ldquo;as is&rdquo; and
              &ldquo;as available&rdquo; basis, meaning that we do not guarantee
              its 24/7 and error-free operation.&nbsp;Inter alia, we shall not
              be liable to you for the damage and other consequences that have
              arisen due to:
              <br />
              (a) permanent or temporary interruption, discontinuance,
              suspension or other type of unavailability of the Application;
              <br />
              (b) errors, damages, or settings in your device that are unsuited
              for the use of the Application (for the details of supported and
              suitable devices please contact us at&nbsp;info@leverid.com);
              <br />
              (c) changes in legal acts and in their interpretations, their
              impacts on you and implementing those changes in the Application,
              unless it is obligatory to us under the law or a court decision
              made regarding us;
              <br />
              (d) any modifications in these Terms.
            </p>
            <h3>6. TERM AND TERMINATION</h3>
            <p>
              6.1. The Agreement shall be effective between you and us as of the
              moment when you accepted these Terms.
            </p>
            <p>
              6.2. The Agreement is entered into for an indefinite term and may
              be terminated by either Party anytime without any reason.
            </p>
            <p>
              6.3. The Agreement ends automatically and without notice once we
              remove the Application from our test environments which are meant
              and may be accessed for public testing, and/or once we terminate
              our users&rsquo; access to these test environments.
            </p>
            <h3>7. GOVERNING LAW AND DISPUTE RESOLUTION</h3>
            <p>
              7.1. The Agreement shall be governed and construed in accordance
              with laws of Republic of Estonia. Upon non-compliance of the
              object of the Agreement with terms and conditions of the
              Agreement, you can rely on the legal remedies laid down in &sect;
              101 (1) of the Law of Obligations Act.
            </p>
            <p>
              7.2. The Parties undertake to use their best efforts to resolve
              any dispute, misunderstanding or claim arising out or, or in
              connection with the Agreement, or its breach, termination, or
              invalidity by amicable negotiations.
            </p>
            <p>
              7.3. If the Parties fail to settle disputes through amicable
              negotiations, such dispute, misunderstanding, or claim arising out
              of, or in connection with the Agreement, or its breach,
              termination or invalidity, shall be resolved in Harju County
              Court, Republic of Estonia.
            </p>
            <p>
              7.4. If you are a consumer, you may submit complaints regarding
              this Agreement with the Consumer Disputes Committee (Endla 10A,
              10142 Tallinn, Estonia,
              email:&nbsp;avaldus@komisjon.ee,&nbsp;https://komisjon.ee) or by
              completing an application form on the Electronic Consumer Dispute
              Resolution Platform at&nbsp;
              <a href="http://ec.europa.eu/odr/.&nbsp;">
                http://ec.europa.eu/odr/.&nbsp;
              </a>
            </p>
            <h3>8. MISCELLANEOUS</h3>
            <p>
              8.1. We have the right to unilaterally change the Terms at any
              time, by publishing the new wording of the Terms, together with
              the changes made in the Application. You should review any changes
              to the Terms, and if such changes are not acceptable to you,
              immediately terminate your use of the Application.
            </p>
            <p>
              8.2. Illegality, invalidity, or unenforceability of any provision
              or provisions of these Terms does not affect the validity,
              legality, or enforceability of the remaining provisions of these
              Terms. If any provision proves to be illegal, invalid, or
              unenforceable, the Parties will in good faith use all reasonable
              endeavours to replace that provision with a new, lawful, valid,
              and enforceable provision closest to the commercial substance of
              original provision.
            </p>
            <p>
              8.3. Failure of either Party to exercise or enforce any provision
              or any of its rights under these Terms shall not be deemed a
              waiver of future enforcement of that or any other provision or
              right.
            </p>
          </Row>
        </Container>
      </Container>
    </Layout>
  );
}

export default IndexPage;
